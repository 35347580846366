import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BlogItem = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityBlog(limit: 2, sort: { _createdAt: DESC }) {
        nodes {
          blogTitle
          slug {
            current
          }
          thumbnailImage {
            alt
            image {
              asset {
                gatsbyImageData(
                  formats: WEBP
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `);
  const blogs = data.allSanityBlog.nodes;
  return (
    <div className="col lg:col-lg-4">
      <div className="mb-[50px] lg:mb-0">
        {/* Title */}
        <div>
          <h5 className="mb-[30px]">Recent News</h5>
        </div>
        {/* Adresses */}
        <ul className="mb-4">
          {blogs.length > 0 &&
            blogs.map((blog, index) => (
              <Link
                to={`/blog/${blog.slug.current}`}
                key={index}
                className="flex mb-[25px]"
              >
                {/* Icon */}
                <div className="w-full max-w-[100px] mr-[15px]">
                  <GatsbyImage
                    image={blog.thumbnailImage.image.asset.gatsbyImageData}
                    alt={blog.thumbnailImage.alt}
                    className="max-w-[92px] aspect-video"
                    objectFit="cover"
                  />
                </div>

                {/* Content */}
                <div>
                  <p className="text-[13px] text-[#a4a7b1] leading-6 font-light">
                    {blog.blogTitle}
                  </p>
                  {/* <span className="text-xs text-[#12c2e9]">14 sep 2022</span> */}
                </div>
              </Link>
            ))}
          <li>
            <div className="relative w-full">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Type your email"
                className="w-full p-[15px] rounded-full border border-solid border-white/5 bg-transparent text-white text-[13px]
                placeholder:capitalize"
              />
              <span
                role="button"
                className="absolute top-0 right-0 w-[50px] h-[50px] leading-[50px] text-center rounded-full bg-[#12c2e9] text-black
                flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 -rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogItem;
