import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const ContactUsItem = ({ data }) => {
  return (
    <div className="col lg:col-lg-4">
      <div className="mb-[50px] lg:mb-0">
        {/* Title */}
        <div>
          <h5 className="mb-[30px]">{data.title}</h5>
        </div>
        {/* Adresses */}
        <ul className="mb-4">
          <li className="flex items-center mb-[25px]">
            {/* Icon */}
            <span className="text-3xl mr-[30px]">
              <GatsbyImage
                image={data.addressIcon.asset.gatsbyImageData}
                alt={data.address}
                className="w-[30px] h-[30px]"
                loading="lazy"
              />
            </span>
            {/* Content */}
            <div>
              <h6 className="text-sm font-semibold">{data.address}</h6>
              {/* <p className="text-base text-[#a4a7b1] leading-8 font-light">
                504 White St . Dawsonville, GA 30534 , New York
              </p> */}
            </div>
          </li>
          <li className="flex items-center mb-[25px]">
            {/* Icon */}
            <span className="text-3xl mr-[30px]">
              <GatsbyImage
                image={data.emailIcon.asset.gatsbyImageData}
                alt={data.email}
                className="w-[30px] h-[30px]"
                loading="lazy"
              />
            </span>
            {/* Content */}
            <div>
              <h6 className="text-sm font-semibold">{data.email}</h6>
              {/* <p className="text-base text-[#a4a7b1] leading-8 font-light">
                support@gmail.com
              </p> */}
            </div>
          </li>
          <li className="flex items-center mb-[25px]">
            {/* Icon */}
            <span className="text-3xl mr-[30px]">
              <GatsbyImage
                image={data.phoneIcon.asset.gatsbyImageData}
                alt={data.phone}
                className="w-[30px] h-[30px]"
                loading="lazy"
              />
            </span>
            {/* Content */}
            <div>
              <h6 className="text-sm font-semibold">{data.phone}</h6>
              {/* <p className="text-base text-[#a4a7b1] leading-8 font-light">
                +87986451666
              </p> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactUsItem;
